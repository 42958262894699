import React, { Component } from 'react';
import './Style.css';
import Footer from "./Footer";
import './Site_name';


class Sustainability extends Component {
    constructor(){
        super();
        this.state={
            slider_imgs:[],
            site:global.site_name,
        }
    }
    componentDidMount() {
        
        fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/header_slider_images/${this.state.site}/5`).then((resp)=>{
        resp.json().then((result)=>{
            this.setState({ slider_imgs: result.data.header_slider_image});
        })
    })
}
render() {
    const {slider_imgs} = this.state;
    return (
        <>
        <div>
        <div className=" overflow-auto box-height bg-white" >
        <div className="container-fluid" id="banner_section" >
    <div className="row">
    <div className="col-md-12 col-12">
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
    {slider_imgs.map((slider_img, index) =>
      <div className={`${index == 0 ? "carousel-item active" : "carousel-item"}`}>
      <img className="d-block w-100 carousel-height"  src={`https://admin.foodweb.ksoftpl.com/assets/slider_banner_images/${slider_img.all_slider_images}`} alt="First slide" />
      <div class="carousel-caption d-md-block">
      <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={3000} className={`title title1 animated slider_content fadeInUp header_slider_text_${slider_img.all_text_align} header_slider_position_${slider_img.all_text_format}`} style={{textAlign: 'center', color: '#ffffff', backgroundColor: '#c0c0c0'}}>{slider_img.all_title_name}</h3>
      </div>
      </div>
      )}
      </div>
      {
              slider_imgs.length >1 ?   
              <>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
              </a>
              </>
              : null
            }
      </div>
      
      </div>
      </div>
      </div>
        {/* <img src="./assets/images/background/sustain_banner_new.png " id="banner_section" className="sustain_banner img-fluid" alt="Sustainability banner"/> */}
        
        <div className=" p-4">
        <h1 className="text-center big_font para" style={{fontSize: '45px'}}>Sustainable Food</h1>
        </div>    
        
        <div className="container-fluid">
        <div className="row contain_space">
        <div className="col-md-12 col-xl-12 col-12">                        
        <div className="entrt-description container">
        <p className="para">A sustainable food system is a collaborative network that integrates several components in order to enhance a community’s environmental, economic and social well-being.
        It is built on principles that further the ecological, social and economic values 
        of a community and region.</p>
        <p className="para">
        Dining Services actively explores and implements sustainable practices to help improve the environment where we live and work. 
        Here are the characteristics of a sustainable food system: </p>
        </div>
        </div>
        
        <div className="col-xl-6 col-md-6 col-12 para">
        <ul className="list list-unstyled mb-20 p-2">
        <li> <i className="fa fa-hand-o-right" /> Is secure reliable and resilient to climate change and rising energy.</li>
        <li> <i className="fa fa-hand-o-right" /> Is energy-efficient </li>
        <li> <i className="fa fa-hand-o-right" /> Is healthy and safe </li>
        <li> <i className="fa fa-hand-o-right" /> Is environmentally beneficial </li>
        </ul>
        
        </div>
        
        <div className="col-xl-6 col-md-6 col-12 para">
        <ul className="list list-unstyled mb-20 p-2">
        <li> <i class="fa fa-hand-o-right"></i> Leverages local capacity </li>
        <li> <i class="fa fa-hand-o-right"></i> Is celebrated through events, promotions, etc. </li>
        <li> <i class="fa fa-hand-o-right"></i> Has a strong educational focus to create awareness </li>
        <li> <i class="fa fa-hand-o-right"></i> Is fairly traded </li>
        </ul>
        
        </div>
        </div>
        </div>  
        
        <div className="container-fluid mt-4">
        <div className="row contain_space">
        
        <div className="col-xl-12 col-12 text-center">
        <h1 className="short-heading">We are committed to these sustainable practices:</h1>
        </div>
        
        <div className="col-xl-6 col-md-6 col-12">
        <p className="para fa bold"><strong><img src="https://admin.foodweb.ksoftpl.com/img/sustain/groceries.png" style={{marginRight: 10}} />SUSTAINABLE FOOD</strong></p>
        <div class="testimonial-block left para">
        <div class="testimonial-info clearfix">
        <ul class="list list-unstyled mb-20 mt-10 p-2">
        <li> <i class="fa fa-hand-o-right"></i> <b style={{color:'#F4511E'}}> Local Produce :</b> We have partnered with our suppliers to offer you fresh, local, and in-season produce. </li>
        <li > <i class="fa fa-hand-o-right"></i> <b style={{color:'#F4511E'}}>Cooking Practices :</b> We closely monitor all cooking processes to ensure best practices. We have also restricted usage of certain ingredients like Mono Sodium Glutamate (MSG), Palmolein Oil, Food Colours, etc. </li>
        </ul>										
        </div>
        </div>
        </div>
        <div className="col-xl-6 col-md-6 col-12">
        <p className="para fa bold"><strong><img src="https://admin.foodweb.ksoftpl.com/img/sustain/recycle-bin (1).png" style={{marginRight: 10}} />WASTE REDUCTION & RECYCLING</strong></p>
        <div class="testimonial-block left para">
        <div class="testimonial-info clearfix">
        <ul class="list list-unstyled mb-20 mt-10 p-2">
        <li> <i class="fa fa-hand-o-right"></i> Efficient water filtration machines placed in most of the cafe areas to reduce reliance on packaged water and use of plastic. </li>
        <li> <i class="fa fa-hand-o-right"></i> Elimination of disposable plastic. </li>
        <li> <i class="fa fa-hand-o-right"></i> Encouraging suppliers to reduce, reuse and recycle. </li>
        </ul>										
        </div>
        </div>
        </div>
        </div>
        
        <div className="row contain_space">
        <div className="col-xl-6 col-md-6 col-12">
        <p className="para fa bold"><strong><img src="https://admin.foodweb.ksoftpl.com/img/sustain/drop (1).png" style={{marginRight: 10}} />WATER EFFICIENCY</strong></p>
        <div class="testimonial-block left para">
        <div class="testimonial-info clearfix">
        <ul class="list list-unstyled mb-20 mt-10 p-2">
        <li> <i class="fa fa-hand-o-right"></i> Modern, conveyer belt-type dishwashing machines present in all sites to ensure efficient use of water, chemicals and energy. </li>
        </ul>										
        </div>
        </div>
        </div>
        <div className="col-xl-6 col-md-6 col-12">
        <p className="para fa bold"><strong><img src="https://admin.foodweb.ksoftpl.com/img/sustain/responsible-energy-use.png" style={{marginRight: 10}} />ENERGY EFFICIENCY</strong></p>
        <div class="testimonial-block left para">
        <div class="testimonial-info clearfix">
        <ul class="list list-unstyled mb-20 mt-10 p-2">
        <li> <i class="fa fa-hand-o-right"></i> Energy-efficient equipment used in all areas. </li>
        <li> <i class="fa fa-hand-o-right"></i> Proactively explore other means / ways to help reduce consumption. </li>
        </ul>										
        </div>
        </div>
        </div>
        </div>
        
        </div> 
        
        <div style={{textAlign:'center'}} className="pt-3">
        <p className="fa para" style={{paddingLeft:'10px', paddingRight:'10px'}}><b>Source: </b><a href="https://www.calgary.ca/CA/cmo/Pages/Definition-of-a-Sustainable-Food-System.aspx" target="_blank">https://www.calgary.ca/CA/cmo/Pages/Definition-of-a-Sustainable-Food-System.aspx</a></p>
        </div>
        </div>
        <Footer />
            </div>
        </>
        );
    }
}

export default Sustainability;