import React, { Component } from 'react';
import Footer from './Footer';
import './Site_name';

class Healthy_Recipes extends Component {
  
  constructor(){
    super();
    this.state={
      recipes:[],
      slider_imgs:[],
      site:global.site_name,
    }
  }
  componentDidMount() {
    
    fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/get_healthy_recipes/${this.state.site}`).then((resp)=>{
    resp.json().then((result)=>{
      this.setState({ recipes: result.data.healthy_recipes});
    })
  })
  
  fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/header_slider_images/${this.state.site}/2`).then((resp)=>{
  resp.json().then((result)=>{
    this.setState({ slider_imgs: result.data.header_slider_image});
  })
})
}

render() {
  const {recipes, slider_imgs} = this.state;
  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  return (
    <div>
    <div className="overflow-auto box-height bg-white">
    <div className="container-fluid" id="banner_section">
    <div className="row">
    <div className="col-md-12 col-12">
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
    {slider_imgs.map((slider_img, index) =>
      <div className={`${index == 0 ? "carousel-item active" : "carousel-item"}`}>
      <img className="d-block w-100 carousel-height" src={`https://admin.foodweb.ksoftpl.com/assets/slider_banner_images/${slider_img.all_slider_images}`} alt="First slide" />
      <div className="carousel-caption d-md-block">
      <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={3000} className="title title1 animated slider_content fadeInUp" style={{textAlign: 'center', color: '#ffffff', backgroundColor: '#c0c0c0'}}>{renderHTML(slider_img.all_title_name)}</h3>
      </div>
      </div>
      )}
      </div>
      {
              slider_imgs.length >1 ?   
              <>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
              </a>
              </>
              : null
            }
      </div>
      
      </div>
      </div>
      </div>
      {/* start content -*/}
      <div className="container mt-5">
      <div className="row">
      <div className="col-md-12">
      <div className="swin-sc swin-sc-blog-grid">
      <div className="row">
      {recipes.map(healthy_recipes => 
        <div className="col-md-6 col-sm-6 col-xs-12">
        <div data-wow-delay="0s" className="blog-item swin-transition item wow fadeInUpShort" style={{marginBottom: 20, visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpShort'}}>
        <div className="blog-featured-img"><img src={`https://admin.foodweb.ksoftpl.com/assets/healthy_images_banner/${healthy_recipes.hr_image}`} alt="fooday" className="img img-responsive banner_img" /></div>
        <div className="blog-content">
        <div className="blog-date view-more-button" data-toggle="collapse" data-target={`#demo_${healthy_recipes.hr_id}`}>view more</div>
        <h3 className="blog-title"><a href="javascript:void(0)" className="swin-transition para" style={{fontSize:'22px'}}>{healthy_recipes.hr_title}</a></h3>
        <div className="product-info">
        <ul className="list-inline">
        <li className="author"><span>Preparation time</span><span className="text">{healthy_recipes.hr_pre_time}</span></li>
        <li className="author"><span>Total time</span><span className="text">{healthy_recipes.hr_total_time}</span></li>
        <li className="author"><span> Serves:</span><span className="text"> {healthy_recipes.hr_serves}</span></li>
        </ul>
        </div>
        <div id={`demo_${healthy_recipes.hr_id}`} className="collapse">
        <h3 className="blog-title"><a href="javascript:void(0)" className="swin-transition para" style={{fontSize:'22px'}}>Ingredients</a>
        </h3>
        <div className="para"> {renderHTML(healthy_recipes.hr_ingredients)}
        </div>
        <div className="product-info para">
        <h3 className="blog-title"><a href="javascript:void(0)" className="swin-transition" /></h3>
        <h3 className="blog-title"><a href="javascript:void(0)" className="swin-transition para" style={{fontSize:'22px'}}>Directions</a></h3>	
        <div>{renderHTML(healthy_recipes.directios)}
        </div>
        {/* <h3 className="blog-title"><a href="javascript:void(0)" className="swin-transition">Vinaigrette</a></h3>
        <ul className="list list-unstyled mb-20">
        <li> <span className="text-orange" style="font-weight: bold;padding-right: 10px;">1.</span> In a small bowl, combine all the ingredients together and whisk until blended. Season with salt and pepper, to taste. </li>
      </ul> */}
      </div>
      </div>
      </div>
      </div>
      </div>
      )}
      </div>
      </div>
      </div>
      </div>
      </div>      
      </div>
      <Footer />
            </div>
      );
    }
  }
  
  export default Healthy_Recipes;