import React, { Component } from 'react';
import Footer from './Footer';
import './Site_name';

class Special_recipes extends Component {
  
  constructor(){
    super();
    this.state={
      recipes:[],
      slider_imgs:[],
      site:global.site_name,
    }
  }
  
  componentDidMount() {
    
    fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/get_special_recipes/${this.state.site}`).then((resp)=>{
    resp.json().then((result)=>{
      this.setState({ recipes: result.data.special_recipes});
    })
  })
  
  fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/header_slider_images/${this.state.site}/8`).then((resp)=>{
  resp.json().then((result)=>{
    this.setState({ slider_imgs: result.data.header_slider_image});
  })
})
}


render() {
  const {recipes, slider_imgs} = this.state;
  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  return (
    <div>
    <div className="overflow-auto box-height bg-white">
    <div className="container-fluid" id="banner_section" >
    <div className="row">
    <div className="col-md-12 col-12">
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
    {slider_imgs.map((slider_img, index) =>
      <div className={`${index == 0 ? "carousel-item active" : "carousel-item"}`}>
      <img className="d-block w-100 carousel-height" src={`https://admin.foodweb.ksoftpl.com/assets/slider_banner_images/${slider_img.all_slider_images}`} alt="First slide" />
      <div  className={`carousel-caption  d-md-block header_slider_position_${slider_img.all_text_format}`}>
      <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={3000}  className={`title title1 animated slider_content fadeInUp header_slider_text_${slider_img.all_text_align} header_slider_position_${slider_img.all_text_format}`} style={{ color: '#ffffff', backgroundColor: '#c0c0c0'}}>{renderHTML(slider_img.all_title_name)}</h3>
      </div>
      </div>
      )}
      </div>
      {
              slider_imgs.length >1 ?   
              <>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
              </a>
              </>
              : null
            }
                 
      </div>
      
      </div>
      </div>
      </div>
      {/* main meal counter */}
      
      <div className="container pt-2" style={{background:'#000'}}>
      <div className="row">
      <div className="col-md-12">
      <div className="swin-sc swin-sc-blog-grid">
      <div className="row">
      {recipes.map(special_recipes => 
        <div className="col-md-6 col-sm-6 col-xs-12">
        <div data-wow-delay="0s" className="blog-item swin-transition item wow fadeInUpShort" style={{marginBottom: 20, visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpShort'}}>
        <div className="blog-featured-img"><img src={`https://admin.foodweb.ksoftpl.com/assets/wellness_images_banner/${special_recipes.w_image}`} alt="fooday" className="img img-responsive banner_img" /></div>
        <div className="blog-content">
        <div className="blog-date" data-toggle="collapse" data-target={`#demo_${special_recipes.w_id}`} style={{backgroundColor:'#f15f2a',cursor:'pointer'}}>View</div>
        
        <div className="product-info">
        <ul className="list-inline">
        <li className="author"><span>Preparation time</span><span className="text">{special_recipes.w_pre_time}</span></li>
        <li className="author"><span>Total time</span><span className="text">{special_recipes.w_total_time}</span></li>
        <li className="author"><span> Serves:</span><span className="text"> {special_recipes.w_serves}</span></li>
        </ul>
        </div>
        <h3 className="blog-title" style={{color:'#000 !important'}}><a href="javascript:void(0)" className="swin-transition para recipe-heading" >{special_recipes.w_title}</a></h3>
        <div id={`demo_${special_recipes.w_id}`} className="collapse">
        <h3 className="blog-title"><a href="javascript:void(0)" className="swin-transition para recipe-heading">Ingredients</a>
        </h3>
        {/* <ul className="list list-unstyled mb-20 para"> */}
        <div className="para"> {renderHTML(special_recipes.w_ingredients)}
        </div>
        {/* </ul> */}
        <div className="product-info para">
        <h3 className="blog-title"><a href="javascript:void(0)" className="swin-transition" /></h3>
        
        <h3 className="blog-title"><a href="javascript:void(0)" className="swin-transition para recipe-heading">Directions</a></h3>	
        <div>{renderHTML(special_recipes.w_directions)}
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        )}
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        <Footer />
            </div>
        );
      }
    }
    
    export default Special_recipes;