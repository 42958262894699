import React, { Component } from 'react';
import Footer from './Footer';
import './Style.css';
import './Site_name';

class Glutenfree extends Component {
    constructor(){
        super();
        this.state={
            slider_imgs:[],
            site:global.site_name,
        }
    }
    componentDidMount() {
        
        fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/header_slider_images/${this.state.site}/3`).then((resp)=>{
        resp.json().then((result)=>{
            this.setState({ slider_imgs: result.data.header_slider_image});
        })
    })
}
render() {
    const {slider_imgs} = this.state;
    return (
        <div>
        <div className="overflow-auto box-height bg-white">
        <div className="container-fluid" id="banner_section" >
        <div className="row">
        <div className="col-md-12 col-12">
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
        {slider_imgs.map((slider_img, index) =>
            <div className={`${index == 0 ? "carousel-item active" : "carousel-item"}`}>
            <img className="d-block w-100 carousel-height"  src={`https://admin.foodweb.ksoftpl.com/assets/slider_banner_images/${slider_img.all_slider_images}`} alt="First slide" />
            {/* <div className="carousel-caption d-none d-md-block">
            <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={3000} className="title title1 animated slider_content fadeInUp" style={{textAlign: 'center', color: '#ffffff', backgroundColor: '#c0c0c0'}}>{slider_img.all_title_name}</h3>
            </div> */}
            </div>
            )}
            </div>
            {
              slider_imgs.length >1 ?   
              <>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
              </a>
              </>
              : null
            }
            </div>
            
            </div>
            </div>
            </div>
            {/* <img src="https://admin.foodweb.ksoftpl.com/assets/slider_banner_images/gluten_free1.jpg" id="banner_section" className="sustain_banner img-fluid" alt="gluten banner"/> */}
            
            <div className=" p-4">
            <h1 className="text-center big_font para" style={{fontSize:'45px'}}>What’s A Gluten-Free Diet?</h1>
            </div>    
            
            <div className="container-fluid">
            <div className="row contain_space">
            <div className="col-md-12 col-xl-12 col-12">                        
            <div className="entrt-description">
            <p className="para">A gluten-free diet is a diet that excludes the protein gluten. Gluten is found in grains such as wheat, barley, rye, and triticale (a cross between wheat and rye). To be gluten-free, one must avoid these ingredients and choose substitutes that can provide nutrients for a healthy diet.
            
            </p>
            <p className="para">This kind of diet is essential to managing signs and symptoms of Celiac disease, an immune disease where ingestion of gluten leads to damage in the small intestine, and other medical conditions related to gluten. It is also popular among individuals without gluten-related diseases due to its proven benefits such as improved health, weight loss and increased energy.
            
            </p>
            <p className="para">
            Before following a gluten-free diet, it's important to know how it can affect your overall nutritional needs. Consulting your doctor or dietitian can help you in making the appropriate dietary choices to maintain a well-balanced lifestyle.
            
            
            </p>
            <h1 className="big_heading">How Do I Start?</h1>
            <p className="para">Following a gluten-free diet requires paying careful attention to both the food ingredients and their nutritional content.
            
            </p>
            </div>
            </div>
            
            <div className="col-xl-6 col-md-6 col-12 para">
            
            <ul className="list list-unstyled mb-20 p-4">
            <h4 className="text-center card_title">ALLOWED FRESH FOODS</h4>
            <li>Here are some examples of naturally gluten-free foods:</li>
            <li><i className="fa fa-hand-o-right"></i> Fruits and vegetables </li>
            <li><i className="fa fa-hand-o-right"></i> Beans, seeds and nuts in their natural, unprocessed forms </li>
            <li><i className="fa fa-hand-o-right"></i> Eggs </li>
            <li><i className="fa fa-hand-o-right"></i> Lean, nonprocessed meats, fish and poultry </li>
            <li><i className="fa fa-hand-o-right"></i> Most low-fat dairy products </li><br/>
            <li>Here are examples of grains, starch or flour that can be part of a gluten-free diet:</li>
            <li><i className="fa fa-hand-o-right"></i> Amaranth </li>
            <li><i className="fa fa-hand-o-right"></i> Arrowroot </li>
            <li><i className="fa fa-hand-o-right"></i> Buckwheat </li>
            <li><i className="fa fa-hand-o-right"></i> Corn and cornmeal </li>
            <li><i className="fa fa-hand-o-right"></i> Flax </li>
            <li><i className="fa fa-hand-o-right"></i> Gluten-free flours (rice, soy, corn, potato, bean) </li>
            <li><i className="fa fa-hand-o-right"></i> Hominy (corn) </li>
            <li><i className="fa fa-hand-o-right"></i> Millet </li>
            <li><i className="fa fa-hand-o-right"></i> Quinoa </li>
            <li><i className="fa fa-hand-o-right"></i> Rice </li>
            <li><i className="fa fa-hand-o-right"></i> Sorghum </li>
            <li><i className="fa fa-hand-o-right"></i> Soy </li>
            <li><i className="fa fa-hand-o-right"></i> Tapioca (cassava root) </li>
            <li><i className="fa fa-hand-o-right"></i> Teff </li>
            </ul>
            
            </div>
            
            <div className="col-xl-6 col-md-6 col-12 para">
            <ul className="list list-unstyled mb-20 p-4">
            <h4 className="text-center card_title">PROCESSED FOOD THAT OFTEN CONTAIN GLUTEN</h4>
            <li>In general, avoid the following foods unless they're labeled as gluten-free or made with corn, rice, soy or other gluten-free grain:</li>
            <li><i className="fa fa-hand-o-right"></i> Beer, ale, porter, stout (usually barley) </li>
            <li><i className="fa fa-hand-o-right"></i> Breads </li>
            <li><i className="fa fa-hand-o-right"></i> Bulgur </li>
            <li><i className="fa fa-hand-o-right"></i> Cakes and pies </li>
            <li><i className="fa fa-hand-o-right"></i> Candies </li>
            <li><i className="fa fa-hand-o-right"></i> Cereals </li>
            <li><i className="fa fa-hand-o-right"></i> Communion wafers </li>
            <li><i className="fa fa-hand-o-right"></i> Cookies and crackers </li>
            <li><i className="fa fa-hand-o-right"></i> Croutons </li>
            <li><i className="fa fa-hand-o-right"></i> French fries </li>
            <li><i className="fa fa-hand-o-right"></i> Gravies </li>
            <li><i className="fa fa-hand-o-right"></i> Imitation meat or seafood </li>
            <li><i className="fa fa-hand-o-right"></i> Malt, malt flavoring and other malt products (barley) </li>
            <li><i className="fa fa-hand-o-right"></i> Matzo </li>
            <li><i className="fa fa-hand-o-right"></i> Pastas </li>
            <li><i className="fa fa-hand-o-right"></i> Hot dogs and processed luncheon meats </li>
            <li><i className="fa fa-hand-o-right"></i> Salad dressings </li>
            <li><i className="fa fa-hand-o-right"></i> Sauces, including soy sauce </li>
            <li><i className="fa fa-hand-o-right"></i> Seasoned rice mixes </li>
            <li><i className="fa fa-hand-o-right"></i> Seasoned snack foods, such as potato and tortilla chips </li>
            <li><i className="fa fa-hand-o-right"></i> Self-basting poultry </li>
            <li><i className="fa fa-hand-o-right"></i> Soups, bouillon or soup mixes </li>
            <li><i className="fa fa-hand-o-right"></i> Vegetables in sauce </li>
            </ul>
            
            </div>
            
            <div className="col-xl-6 col-md-6 col-12 para">
            
            <ul className="list list-unstyled mb-20 p-4">
            <h4 className="text-center card_title">TYPES OF GRAINS THAT ARE NOT ALLOWED</h4>
            <li>Please note that these grains are standard ingredients in several products. For example, wheat or wheat gluten is added as a thickening or binding agent, flavoring, or coloring. It's important to read the labels and ingredients to determine if they contain wheat, barley or rye.
            </li><br/>
            <li>Avoid all foods and drinks containing the following:</li>
            
            <li><i className="fa fa-hand-o-right"></i> Wheat </li>
            <li><i className="fa fa-hand-o-right"></i> Barley </li>
            <li><i className="fa fa-hand-o-right"></i> Rye </li>
            <li><i className="fa fa-hand-o-right"></i> Triticale (a cross between wheat and rye) </li>
            
            </ul>
            
            </div>
            
            
            </div>
            <div className="contain_space">
            <h1 className="big_heading">Disclaimer</h1>
            <p className="para">This information is not to be understood as a medical advice. Please consult your doctor/nutritionist to help determine the correct diet plan for you.</p>
            </div>
            </div>  
            <div style={{textAlign:'center'}} className="pt-3">
            <p className="para fa" style={{paddingLeft:'10px', paddingRight:'10px'}}><b style={{fontWeight:'bold'}}>Source: </b><a href="
            https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/gluten-free-diet/art-20048530" target="_blank">
            https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/gluten-free-diet/art-20048530</a></p>
            
            </div>
            </div>
            <Footer />
            </div>
            );
        }
    }
    
    export default Glutenfree;