import React, { Component } from 'react';
import Footer from './Footer';
import './Style.css';
import './Site_name';

class Pantryservice extends Component {
  constructor(){
    super();
    this.state={
      slider_imgs:[],
      site:global.site_name,
    }
  }
  componentDidMount() {
    
    fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/header_slider_images/${this.state.site}/9`).then((resp)=>{
    resp.json().then((result)=>{
      this.setState({ slider_imgs: result.data.header_slider_image});
    })
  })
}
render() {
  const {slider_imgs} = this.state;
  return (
    <div>
    <div className=" overflow-auto box-height bg-white" >
    <div className="container-fluid" id="banner_section" >
    <div className="row">
    <div className="col-md-12 col-12">
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
    {slider_imgs.map((slider_img, index) =>
      <div className={`${index == 0 ? "carousel-item active" : "carousel-item"}`}>
      <img className="d-block w-100 carousel-height"  src={`https://admin.foodweb.ksoftpl.com/assets/slider_banner_images/${slider_img.all_slider_images}`} alt="First slide" />
      <div className="carousel-caption d-md-block">
      <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={3000} className="title title1 animated slider_content fadeInUp" style={{textAlign: 'center', color: '#000', backgroundColor: '#c0c0c0'}}>{slider_img.all_title_name}</h3>
      </div>
      </div>
      )}
      </div>
      {
              slider_imgs.length >1 ?   
              <>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
              </a>
              </>
              : null
            }
                 
      </div>
      
      </div>
      </div>
      </div>
    <div className="container-fluid contain_space mt-4 pantry_body">
    <div className="text-center">
    <h2 className="para" style={{fontSize:'18px', color:'#000!important'}}>Our amenities aim to provide the best employee experience across our cafes and pantry areas.</h2>
    </div>
    <div className="row contain_space">
    <div className="col-lg-12 col-md-12">
    <div className="testimonial-block left">
    <div className="testimonial-info clearfix">
    <ul className="list list-unstyled mb-20 mt-10">
    <li> <center><p className="para fa"><strong><img src="https://admin.foodweb.ksoftpl.com/img/officePantries/coffee-machine.png" style={{marginRight: 10}} />BEVERAGE MACHINES</strong></p></center></li>
    <li> <center className="para">Our beverage machines are stacked with a variety of hot and cold beverage options for employees to enjoy for free.</center></li>
    </ul>										
    </div>
    </div>
    </div>
    
    </div>
    
    <div className="row contain_space">
    <div className="col-lg-6 col-md-6 ">
    <div className="testimonial-block left">
    <div className="testimonial-info clearfix">
    <ul className="list list-unstyled mb-20 mt-10  list-unstyled" style={{height: '440px!important'}}>
    <li> <center><p><strong><img className="img-fluid" src="https://admin.foodweb.ksoftpl.com/img/officePantries/image004.png" style={{marginRight: 10}} /></strong></p></center></li>
    </ul>										
    </div>
    </div>
    </div>
    <div className="col-lg-6 col-md-6 ">
    <div className="row list-unstyled" style={{paddingBottom: 0, marginRight: 0, marginLeft: 0}}>
    <div className="col-lg-6 col-md-6 ">	
    <div className="testimonial-block left">
    <div className="testimonial-info clearfix">
    <li className="list mb-20 mt-10" style={{paddingLeft: 0}}>
    <center><p><strong><img className="img-fluid" src="https://admin.foodweb.ksoftpl.com/img/officePantries/coffe_machine_banner.png" style={{marginRight: 10}} /></strong></p></center>
    </li>										
    </div>
    </div>
    </div>
    <div className="col-lg-6 col-md-6">	
    <div className="testimonial-block left">
    <div className="testimonial-info clearfix">
    <li className="list mb-20 mt-10">
    <center><p><strong><img className="img-fluid" src="https://admin.foodweb.ksoftpl.com/img/officePantries/nescafe_machine.png" style={{marginRight: 10, height: 305}} /></strong></p></center>
    </li>										
    </div>
    </div>
    </div>
    <div className="col-lg-12 col-md-12">
    <div className="testimonial-block left">
    <div className="testimonial-info clearfix">
    <li className="list mb-20 mt-10 " style={{marginBottom: 0}}>
    <center><p style={{marginBottom: 0}} className="para fa"><strong>State of the Art Coffee Dispensing Machine</strong></p></center>
    <center className="para">Selection of hot coffees like latte, cappuccino, Café Latte, Espresso,  and cold beverages like ice tea</center>
    <center><p style={{marginBottom: 0}} className="para fa"><strong>One-touch digitalized Machine</strong></p>
    </center>
    <center className="para">Access products easily</center>
    </li>										
    </div>
    </div>
    </div>
    </div>	
    </div>
    </div>
    
    
    <div className="row contain_space mt-2">
    <div className="col-lg-6 col-md-6">
    <div className="testimonial-block left">
    <div className="testimonial-info clearfix">
    <ul className="list list-unstyled mb-20 mt-10">
    <li> <center><p className="para fa"><strong><img src="https://admin.foodweb.ksoftpl.com/img/officePantries/funnel.png" style={{marginRight: 10}} />FILTERED WATER</strong></p></center></li>
    <li> <center className="para">Available through water filter machines that provides hot &amp; cold water</center></li>
    </ul>										
    </div>
    </div>
    </div>
    <div className="col-lg-6 col-md-6">
    <div className="testimonial-block left">
    <div className="testimonial-info clearfix">
    <ul className="list list-unstyled mb-20 mt-10">
    <li> <center><p className="para fa"><strong><img src="https://admin.foodweb.ksoftpl.com/img/officePantries/microwave.png" style={{marginRight: 10}} />MICROWAVE OVEN</strong></p></center></li>
    <li> <center className="para">Available to reheat food. Please make sure to use microwave-safe containers.</center></li>
    </ul>										
    </div>
    </div>
    </div>
    {/*<div className="col-lg-6 col-md-6">
    <div className="testimonial-block left">
    <div className="testimonial-info clearfix">
    <ul className="list list-unstyled mb-20 mt-10">
    <li> <center><p><strong><img src="https://admin.foodweb.ksoftpl.com/img/officePantries/sink.png" style="margin-right:10px;">SINK</strong></p></center></li>
    <li> <center>For ease of washing cups, tiffins, etc</center></li>
    </ul>										
    </div>
    </div>
  </div>*/}
  <div className="col-lg-6 col-md-6 ">
  <div className="testimonial-block left">
  <div className="testimonial-info clearfix">
  <ul className="list list-unstyled mb-20 mt-10">
  <li> <center><p className="para fa"><strong><img src="https://admin.foodweb.ksoftpl.com/img/officePantries/fridge.png" style={{marginRight: 10}} />REFRIGERATOR</strong></p></center></li>
  <li> <center className="para">For storage of food and beverage.<span style={{visibility: 'hidden'}} /></center></li>
  </ul>										
  </div>
  </div>
  </div>
  <div className="col-lg-6 col-md-6 mb-4">
  <div className="testimonial-block left">
  <div className="testimonial-info clearfix">
  <ul className="list list-unstyled mb-20 mt-10">
  <li> <center><p className="para fa"><strong><img src="https://admin.foodweb.ksoftpl.com/img/officePantries/vending-machine.png" style={{marginRight: 10}} />SNACKS VENDING MACHINES</strong></p></center></li>
  <li> <center className="para">Our vending machines are stacked with a full selection of popular snacks, beverages and healthy food – including local favorites.</center></li>
  </ul>										
  </div>
  </div>
  </div>
  </div>
  
  
  </div>
  </div>
  <Footer />
            </div>
  );
}
}

export default Pantryservice;